<template>
  <div v-if="!hasUsedNewSignupFlow">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-body">
                <div class="">
                  <h1>Revamped and better than ever!</h1>
                  <p>
                    To access all the new goodies, you'll need to fill in some
                    more information about yourself.
                    <br />
                    <br />
                    But don't worry, it's quick and easy - and totally worth it.
                    Let's get started!
                  </p>
                  <div class="text-center">
                    <router-link
                      to="/user/student-welcome"
                      class="btn w-200 pink-button-consult"
                      >Let's go!</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
 computed: {
     hasUsedNewSignupFlow(){
      const userRole = this.$store.getters.role
      if (userRole !== 'Student') {
        return true
      }
      if (userRole === 'Student') {
      return localStorage.getItem('notSignedUp') ? false : true
      }
      return true // worst case
    },
},
}
</script>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-content {
  background: transparent linear-gradient(270deg, #bc1e73 0%, #5a2871 100%) 0%
    0% no-repeat padding-box;
  color: white;
  max-width: 550px;
  padding: 5%;
  border-radius: 10px;
}
.pink-button-consult {
  color: #bc1e73;
  background: white;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>
